import React, { useState, useEffect } from 'react';
import { array, string, bool } from 'prop-types';
import GMapsAPI from './utils/GmapsAPI';
import { getDistance } from 'geolib';

import { sortByDistance, sortAlphabetically, sortPremiumToTop } from './utils/Utilities';
import { DealerLocatorView } from './components/DealerLocatorView';
import {
  filterDistance,
  filterBrand,
  filterOnlyPremiumDealer,
  filterDisplayed,
  filterSearchTerm,
} from './utils/Filter.js';

export const DealerLocator = (props) => {
  const {
    title,
    data,
    limitCategoriesTo,
    filterPremiumDealer,
    markerColor = 'blue',
    showBrands = true,
    showContacts = true,
  } = props;

  const [searchParams, setSearchParams] = useState({
    location: '',
    brand: '',
    distance: -1,
    filterPremiumDealer,
    sort: 'sortAlphabetically',
    search: false,
    searchTerm: '',
  });

  const isInitialRender = searchParams.brand === '' && searchParams.distance === -1;

  const [state, setState] = useState({
    list: [],
    brands: [],
    coords: undefined,
  });

  useEffect(() => {
    const listOfDealers = data
      .filter((node) => node.coordinates !== null)
      .map((node) => {
        return {
          ...node,
          name: node.name,
          coords: {
            latitude: node.coordinates.lat,
            longitude: node.coordinates.lng,
          },
          distance: 0,
          phone: node.phone || node.cellphone || '',
          email: node.email || '',
          website: node.website || '',
          categories: node.brands
            ? Array.isArray(node.brands)
              ? node.brands
              : node.brands.split(',').filter(Boolean)
            : [],
          street: node.address,
          contacts: node.contacts,
          address: {
            streetAddr: node.address,
            zip: node.zip,
            city: node.city,
            country: node.country | 'Austria',
          },
        };
      });

    const brands = !limitCategoriesTo ? extractCategories(listOfDealers) : [];

    setState({
      list: listOfDealers,
      brands: brands,
    });
  }, [data]);

  const { list, sort, brand, distance } = searchParams;

  const sortFunctions = {
    sortByDistance: sortByDistance,
    sortPremiumToTop: sortPremiumToTop,
    sortAlphabetically: sortAlphabetically,
  };

  const extractCategories = (dealers) => {
    const categories = new Set();
    dealers.forEach((dealer) => {
      if (dealer.categories && Array.isArray(dealer.categories)) {
        dealer.categories.forEach((category) => {
          categories.add(category);
        });
      } else {
        const cats = dealer.categories.split(',');
        cats.forEach((category) => {
          categories.add(category);
        });
      }
    });

    return Array.from(categories).sort();
  };

  const onChangeSort = (newSort) => {
    if (state.sort !== newSort) {
      setState((oldState) => ({
        ...oldState,
        sort: newSort,
      }));
    }
  };

  const onSearch = (location, brand, distance, searchTerm) => {
    if (location) {
      GMapsAPI.getCoords(location, (coords) => {
        setState((oldState) => ({
          ...oldState,
          list: updateAllDistances(oldState.list, coords),
          coords: coords,
        }));
        setSearchParams(() => ({
          location,
          brand,
          distance,
          searchTerm,
          sort: 'sortByDistance',
          search: true,
        }));
      });
    } else {
      setSearchParams(() => ({
        location,
        brand,
        distance,
        searchTerm,
        sort: 'sortByDistance',
        search: true,
      }));
    }
  };

  // TODO: this seems to be not optimal
  const updateAllDistances = (list, fromCoords) => {
    return list.map((element) => {
      return {
        ...element,
        distance: element.coords
          ? parseFloat((getDistance(fromCoords, element.coords) / 1000).toFixed(2))
          : 'unbekannt',
      };
    });
  };

  const dealerList = state.list
    .filter(filterBrand, searchParams)
    .filter(filterDistance, searchParams)
    .filter(filterOnlyPremiumDealer, searchParams)
    .filter(filterDisplayed, searchParams)
    .filter(filterSearchTerm, searchParams)
    .sort(sortFunctions[sort]);

  return (
    <DealerLocatorView
      markerColor={markerColor}
      dealers={dealerList}
      sort={sort}
      brands={state.brands}
      onChangeSort={onChangeSort}
      onSearch={onSearch}
      title={title}
      renderAll={!searchParams.search}
      showBrands={showBrands}
      showContacts={showContacts}
      location={{
        location: searchParams.location,
        coords: state.coords,
      }}
    />
  );
};

DealerLocator.propTypes = {
  data: array.isRequired,
  limitCategoriesTo: string,
  filterPremiumDealer: bool,
  title: string,
};

DealerLocator.defaultProps = {
  data: [],
  limitCategoriesTo: null,
};
