import React, { useState, useEffect } from 'react';
import { DefaultLayout } from '../../layouts/DefaultLayout';
import { graphql } from 'gatsby';
import { DealerLocator } from '@mangoart/ezagrar/modules/vertriebspartner';
import { PageTitle } from '../../components/Common/PageTitle';

const VertriebspartnerPage = ({ data }) => {
  const [remoteData, setRemoteData] = useState([]);

  /* useEffect(() => {
    fetch('https://ezagrar.cms.mangomedia.at/api/collections/get/dealers?token=fbb84da130c9f05321059bc369e9e8')
      .then((result) => {
        if (result.status === 200) {
          return result.json();
        } else {
          throw new Error('error', result);
        }
      })
      .then((result) => {
        setRemoteData(result.entries);
      });
  }, []); */

  return (
    <DefaultLayout>
      {/*<PageTitle title={'Vertriebspartner'} /> */}
      {remoteData && remoteData.length > 0 && <DealerLocator data={remoteData} />}
    </DefaultLayout>
  );
};

export default VertriebspartnerPage;

export const VertriebspartnerPageQuery = graphql`
  query VertriebspartnerPageQuery($language: String) {
    site: site {
      siteMetadata {
        title
        canonicalSiteUrl
      }
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
