import React, { useState } from 'react';
import { arrayOf, shape, string, func, bool } from 'prop-types';
import clsx from 'clsx';

import { ListOfContacts } from './ListOfContacts';

import { LazyGMap2 } from './LazyGMap2';
import { SearchForm } from './SearchForm';

import { title as titleClass, listContainer, mapContainer, dealersearch } from './DealerLocatorView.module.css';

export const DealerLocatorView = ({
  dealers,
  brands,
  onSearch,
  title,
  markerColor,
  renderAll,
  location,
  showBrands,
  showContacts,
}) => {
  const [allowGoogleMaps, setAllowGoogleMaps] = useState(false);

  return (
    <div className={clsx('defaultWidth', dealersearch)}>
      {allowGoogleMaps ? (
        <div className={mapContainer}>
          <div style={{ height: 500, width: '100%' }}>
            <LazyGMap2
              list={dealers}
              markerColor={markerColor}
              renderAll={renderAll}
              location={location}
              showBrands={showBrands}
              showContacts={showContacts}
            />
          </div>
          <SearchForm brands={brands} onSearch={onSearch} showBrands={showBrands} />
        </div>
      ) : (
        <div className={mapContainer}>
          <div
            className={'googleMapsWarning'}
            style={{ width: '100%', padding: '1em 1.5em', color: "var(--GoogleMapsWarningColor, '#000')" }}
          >
            <h3 className={titleClass} style={{ marginBottom: '1em', fontWeight: 500 }}>
              Verwendung von Google Maps
            </h3>

            <p style={{ fontSize: '1.2em', lineHeight: 1.5 }}>
              Durch die Verwendung von Google Maps bei der Vertriebspartnersuche können wir unsere Vertriebspartner auf
              einer Karte darstellen. Jedoch werden durch die Verwendung von Google Maps Daten an Google übertragen und
              auf den Servern der Firma Google gespeichert. Nähere Informationen dazu finden Sie un unseren
              Datenschutzbestimmungen. Aus rechtlichen Grüßen müssen Sie der Übertragung ihrer privaten Daten explizit
              zustimmen um Google Maps zu verwenden.
            </p>
            <button style={{ marginTop: '1em' }} onClick={() => setAllowGoogleMaps(true)}>
              Ich stimme zu!
            </button>
          </div>
        </div>
      )}
      <div className={listContainer}>
        {title && <h3 className={titleClass}>{title}</h3>}
        <ListOfContacts listOfContacts={dealers} showBrands={showBrands} showContacts={showContacts} />
      </div>
    </div>
  );
};

DealerLocatorView.propTypes = {
  dealers: arrayOf(shape()).isRequired,
  brands: arrayOf(string),
  onSearch: func.isRequired,
  filterPremiumDealer: bool,
  title: string,
};
DealerLocatorView.defaultProps = {};
