import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import GMapsAPI from '../utils/GmapsAPI';

import { MapPopupContent } from './MapPopupContent';
import * as styles from './LazyGMap2.module.css';
import { MarkerClusterer } from '@googlemaps/markerclusterer';

import iconimageBlue from '../images/custom-marker-ezagrar.png';
import iconimageBlueCluster from '../images/custom-marker-ezagrar-cluster.png';
import iconimageGreen from '../images/custom-marker-fendt.png';
import iconimageRed from '../images/custom-marker-schaeffer.png';
import iconimageBobcat from '../images/custom-marker-bobcat.png';
import iconImageUserPosition from '../images/custom-marker-user-position.png';
import ReactDOM from 'react-dom';

const markerIcons = {
  blue: iconimageBlue,
  green: iconimageGreen,
  red: iconimageRed,
  bobcat: iconimageBobcat,
};

export const LazyGMap2 = (props) => {
  const {
    list,
    className,
    center,
    zoom,
    furthestZoom,
    closestZoom,
    markerColor = 'green',
    renderAll,
    location,
    showBrands,
    showContacts,
  } = props;
  const map = useRef();
  const mapContainer = useRef();

  const markers = [];
  const infoWindow = new google.maps.InfoWindow({
    content: "<div id='gmapinfowindow'></div>",
  });

  let mapHasCloseListener = false;

  const clearMarkers = () => {
    markers.forEach((marker, i) => {
      marker.setMap(null);
    });
    markers.length = 0;
  };

  function renderInfoWindowContent(node, showContacts, showBrands) {
    const { email, website, phone, categories, address, name, contacts } = node;

    let content = `<div id="infoWindowContent">
    <div class="infobox_content">
      <h5><span>${name}</span></h5>
      <div>${address.streetAddr}, ${address.zip} ${address.city}</div>`;
    if (phone) {
      content += `<span style="line-height: 1.7;">
            <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg" style="font-size: 1.2em; margin-bottom: -3px;"><path fill="none" d="M0 0h24v24H0z"></path><path d="M6.62 10.79c1.44 2.83 3.76 5.14 6.59 6.59l2.2-2.2c.27-.27.67-.36 1.02-.24 1.12.37 2.33.57 3.57.57.55 0 1 .45 1 1V20c0 .55-.45 1-1 1-9.39 0-17-7.61-17-17 0-.55.45-1 1-1h3.5c.55 0 1 .45 1 1 0 1.25.2 2.45.57 3.57.11.35.03.74-.25 1.02l-2.2 2.2z"></path></svg>
            ${phone}
          </span>`;
    }

    if (showBrands && categories && Array.isArray(categories)) {
      content += `<div><strong>Hersteller:</strong> ${categories.join(', ')}</div>`;
    }

    if (showBrands && contacts && Array.isArray(contacts)) {
      content += `<div><strong>Ansprechpartner:</strong> ${contacts.join(', ')}</div>`;
    }

    content += `<div class="infobox_content_popuplinks">`;

    if (email) {
      content += `
            <a href="mailto: ${email}">
              <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg" style="font-size: 1.2em;"><path fill="none" d="M0 0h24v24H0z"></path><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10h5v-2h-5c-4.34 0-8-3.66-8-8s3.66-8 8-8 8 3.66 8 8v1.43c0 .79-.71 1.57-1.5 1.57s-1.5-.78-1.5-1.57V12c0-2.76-2.24-5-5-5s-5 2.24-5 5 2.24 5 5 5c1.38 0 2.64-.56 3.54-1.47.65.89 1.77 1.47 2.96 1.47 1.97 0 3.5-1.6 3.5-3.57V12c0-5.52-4.48-10-10-10zm0 13c-1.66 0-3-1.34-3-3s1.34-3 3-3 3 1.34 3 3-1.34 3-3 3z"></path></svg>&nbsp;E-Mail
            </a>
          `;
    }

    if (website) {
      content += `
            <a href="${website}" target="_blank">
              <span style="font-size: 1.4em; margin-bottom: -4px">
              <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M0 0h24v24H0z"></path><path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z"></path></svg>
              </span>&nbsp;Besuchen
            </a>`;
    }

    content += `</div>`;
    content = content + `</div></div>`;

    return content;
  }

  const renderMarkers = (nodes, showContact, showBrands) => {
    let bounds = new GMapsAPI.googleMaps.LatLngBounds();

    if (!nodes || nodes.length <= 0) {
      return;
    }

    nodes.forEach((node, i) => {
      const { coordinates, name } = node;
      const icon = markerIcons[markerColor];

      const marker = new GMapsAPI.googleMaps.Marker({
        position: coordinates,
        title: name,
        icon: icon,
      });
      bounds.extend(coordinates);
      marker.metadata = {
        ...node,
      };

      google.maps.event.addListener(marker, 'click', function () {
        map.current.setCenter(marker.getPosition());

        infoWindow.setContent(renderInfoWindowContent(node, showContact, showBrands));
        infoWindow.open(map, this);
      });

      markers.push(marker);
    });

    new MarkerClusterer({
      markers: markers,
      map: map.current,
      renderer: {
        render: ({ markers, _position: position }) => {
          const marker = new google.maps.Marker({
            position: {
              lat: position.lat(),
              lng: position.lng(),
            },
            icon: {
              url: iconimageBlueCluster,
              size: new google.maps.Size(30, 44),
              scaledSize: new google.maps.Size(30, 44),
              labelOrigin: new google.maps.Point(15, 15),
            },
            label: {
              text: String(markers.length),
              fontSize: '14px',
            },
            title: `${markers.length} Vertriebspartner`,
          });

          google.maps.event.addListener(marker, 'click', function () {
            if (map.current.getZoom() >= 15) {
              var markerContent = markers.map((marker) => {
                const { metadata } = marker;
                return renderInfoWindowContent(metadata, showContact, showBrands);
              });

              infoWindow.setContent(markerContent.join('<div class="infobox_content_separator"></div>'));
              infoWindow.open(map, this);
            }
          });

          return marker;
        },
      },
    });

    if (location.coords) {
      const marker = new GMapsAPI.googleMaps.Marker({
        position: { lat: location.coords.latitude, lng: location.coords.longitude },
        title: location.location,
        map: map.current,
        icon: {
          url: iconImageUserPosition,
          scaledSize: new google.maps.Size(25, 37),
        },
        zIndex: 100,
      });
    }

    if (!renderAll) {
      map.current.fitBounds(bounds);
    } else {
      map.current.fitBounds({
        east: 17.1483,
        north: 49.0098,
        south: 46.3786,
        west: 9.5212,
      });
    }

    if (!mapHasCloseListener) {
      map.current.addListener('click', (e) => {
        infoWindow.close();
      });
      map.current.addListener('zoom_changed', (e) => {
        const zoom = map.current.getZoom();
        const nextZoom = Math.max(furthestZoom, Math.min(closestZoom, zoom));
        if (zoom !== nextZoom) map.current.setZoom(nextZoom);
      });
      mapHasCloseListener = true;
    }
  };

  useEffect(() => {
    const onGMAPSAPILOADED = setInterval(() => {
      if (GMapsAPI.googleMaps) {
        map.current = new GMapsAPI.googleMaps.Map(mapContainer.current, {
          center: center,
          zoom: zoom,
        });
        renderMarkers(list, showContacts, showBrands);
        clearInterval(onGMAPSAPILOADED);
      }
    }, 250);

    return () => {
      clearInterval(onGMAPSAPILOADED);
      map.current = null;
    };
  });

  useEffect(() => {
    if (map.current) {
      clearMarkers();
      renderMarkers(list, showContacts, showBrands);
    }
  }, [list]);

  return (
    <div ref={mapContainer} className={clsx(styles.LazyGMap2, className)}>
      Loading
    </div>
  );
};

LazyGMap2.propTypes = {
  className: PropTypes.string,
  list: PropTypes.array,
  closestZoom: PropTypes.number,
  furthestZoom: PropTypes.number,
};
LazyGMap2.defaultProps = {
  className: '',
  closestZoom: 16,
  furthestZoom: 5,
  center: {
    lat: 59.95,
    lng: 30.33,
  },
  list: [],
  zoom: 11,
};
