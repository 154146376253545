import React, { useEffect, useState } from 'react';
import MapAPI from '../../utils/GmapsAPI';

import { LocationField } from './LocationField';
import { DistanceSelect } from './DistanceSelect';
import { BrandSelect } from './BrandSelect';

import { form, title, row, button } from './SearchForm.module.css';
import clsx from 'clsx';

export const SearchForm = (props) => {
  const { onSearch, brands, showBrands } = props;
  const [address, setAddress] = useState('');
  const [distance, setDistance] = useState('0');
  const [brand, setBrand] = useState('');
  const [searchTerm, setSearchTerm] = useState('');

  const submitForm = (e) => {
    e.preventDefault();
    // if (address) {
    onSearch(address, brand, distance, searchTerm);
    // }
  };

  useEffect(() => {
    const invervalCheckMAPSAPI = setInterval(() => {
      if (MapAPI.googleMaps) {
        clearInterval(invervalCheckMAPSAPI);
        askForLocation();
      }
    }, 500);
  }, []);

  const askForLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (pos) => {
          MapAPI.getAddress(pos.coords, setAddress);
        },
        (error) => {
          console.warn(error.code + ' ' + error.message);
          switch (error.code) {
            case error.PERMISSION_DENIED:
              console.log('User denied the request for Geolocation.');
              break;
            case error.POSITION_UNAVAILABLE:
              console.log('Location information is unavailable.');
              break;
            case error.TIMEOUT:
              console.log('The request to get user location timed out.');
              break;
            case error.UNKNOWN_ERROR:
              console.log('An unknown error occurred.');
              break;
          }
        },
      );
    } else {
      console.log('Geolocation is not supported by this browser.');
    }
  };

  return (
    <form className={form} onSubmit={submitForm}>
      <div className={row}>
        <label className="searchformLabel" htmlFor="locationField">
          PLZ/Ort
        </label>
        <LocationField address={address} onChange={setAddress} />
      </div>

      <div className={row} style={{ maxWidth: 150, minWidth: 100 }}>
        <label className="searchformLabel" htmlFor="distanceSelect">
          im Umkreis von
        </label>
        <DistanceSelect id="distanceSelect" onChange={setDistance} />
      </div>

      {showBrands && brands && brands.length > 0 && (
        <div className={row}>
          <label className="searchformLabel" htmlFor="brandSelect">
            Hersteller
          </label>
          <BrandSelect id="brandSelect" brands={brands} onChange={setBrand} />
        </div>
      )}

      <div className={row}>
        <label className="searchformLabel" htmlFor="locationField">
          Name
        </label>
        <input type={'text'} onChange={(e) => setSearchTerm(e.currentTarget.value)} />
      </div>

      <div className={row} style={{ maxWidth: 200, minWidth: 200 }}>
        <button className={clsx(button, 'has-background')}>Partner suchen!</button>
      </div>
    </form>
  );
};
