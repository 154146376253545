export function filterBrand(dealer) {
  const searchParams = this;
  if (!searchParams.brand && searchParams.brand.length <= 0) {
    return true;
  }
  return (
    dealer && Array.isArray(dealer.categories) && dealer.categories.find((category) => category === searchParams.brand)
  );
}

export function filterDistance(dealer) {
  const searchParams = this;
  return dealer && (searchParams.distance <= 0 || dealer.distance < searchParams.distance);
}

export function filterOutOnlyErsatzTeile(dealer) {
  return dealer.accountNumberTraktors && dealer.accountNumberTraktors.length > 2;
}

export function filterOnlyPremiumDealer(dealer) {
  const searchParams = this;
  return searchParams.filterPremiumDealer ? dealer.isPremium : true;
}

export function filterDisplayed(dealer) {
  return dealer.display !== false;
}

export function filterSearchTerm(dealer) {
  const searchParams = this;
  if (searchParams.searchTerm) {
    const searchTerm = searchParams.searchTerm.toLocaleLowerCase();
    return dealer.name.toLocaleLowerCase().includes(searchTerm);
  }
  return true;
}
