import React from 'react';
import PropTypes from 'prop-types';

import {
  dealers__list,
  dealers__listitem,
  dealers__listitemcontent,
  buttons,
  addressLine,
} from './ListOfContacts.module.css';

const Contact = ({ data, showBrands, showContacts }) => {
  const { name, address, categories, distance, phone, website, email, contacts } = data;
  return (
    <div className={dealers__listitem}>
      <div className={dealers__listitemcontent}>
        <h5 style={{ display: 'block', lineHeight: '1.5em', fontWeight: 'bold' }}>
          <span>{name}</span>
          {distance ? <span style={{ float: 'right' }}>{distance} km</span> : null}
        </h5>

        <div className={addressLine}>
          {address.streetAddr}, {address.zip} {address.city}
        </div>
        {phone && <span style={{ display: 'inline-block', lineHeight: '1.5em' }}> Tel. {phone} </span>}

        {showBrands && categories && Array.isArray(categories) && categories.length > 0 && (
          <div
            style={{
              width: '100%',
              /* textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap', */
              minWidth: 0,
            }}
          >
            <strong>Hersteller:</strong> {categories.join(', ')}
          </div>
        )}
        {showContacts && contacts && Array.isArray(contacts) && contacts.length > 0 && (
          <div
            style={{
              width: '100%',
              /* textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap', */
              minWidth: 0,
            }}
          >
            <strong>Ansprechpartner:</strong> {contacts.join(', ')}
          </div>
        )}
        <div className={buttons}>
          {email && (
            <a href={`mailto: ${email}`}>
              <button className="has-background">E-Mail senden</button>
            </a>
          )}
          {website && (
            <a href={`${website}`} target="_blank">
              <button className="has-background">Webseite besuchen</button>
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

export const ListOfContacts = ({ listOfContacts, showBrands, showContacts }) => {
  return (
    <section className={dealers__list}>
      {listOfContacts &&
        listOfContacts.map((contact) => (
          <Contact key={contact._id} data={contact} showBrands={showBrands} showContacts={showContacts} />
        ))}
    </section>
  );
};

ListOfContacts.propTypes = {
  listOfContacts: PropTypes.array,
};

ListOfContacts.defaultProps = {
  listOfContacts: null,
};
